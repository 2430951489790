@import "../theme-variables.scss";

/** Customize Material Dialog Container - START **/

mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface{
  padding: 20px;
  .mdc-dialog__content{
    letter-spacing: normal !important;
  }
  .mat-mdc-dialog-title{
    padding: 0;
    // min-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: normal;
    line-height: 20px;
  }
  .mdc-dialog__title::before{
    height: 0;
  }
  .mdc-dialog__content{
    color: #000000de;
  }

  .mat-mdc-dialog-content {
    margin: 0 -20px;
    padding: 0 20px;
    min-height: 100px;
    line-height: 20px;
  }

  .mat-mdc-dialog-actions {
    padding: 0;
    min-height: unset;
    margin-bottom: 0;
    &.action-wrapper {
      justify-content: flex-end;
      display: flex;
      c-form-button {
        margin-left: 10px;
      }
    }
  }

  // Override Mat Option Style
  .mat-mdc-option {
    padding: 0 8px;
    &:hover {
      background: none;
    }
  }
}

.a-dialog-container .mat-mdc-dialog-surface {
  padding-bottom: 0!important;
}

.main-dialog-container .mat-mdc-dialog-surface {
  padding-bottom: 0 !important;
}

.outer-dialog-container .mat-mdc-dialog-surface {
  padding: 0 !important;
}

.dialog-title {
  margin-top: 14px;
  margin-bottom: 42px;
  font-size: 20px;
  color: #1b4266;
  height: 30px;
  font-weight: normal;
  font-stretch: normal;
}
/** Customize Material Dialog Container - END **/

.dialog-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  &.p-lg {
    padding: 38px;
  }
  &.p-md {
    padding: 20px;
  }
  &.p-0 {
    padding: 0px;
  }
}

// Global
.cdk-overlay-pane {
  &.p-0-container .mat-mdc-dialog-surface {
    padding: 0px;
    // #verify-transaction-dialog {
    //   padding: 20px !important;
    // }
  }

  &.p-md-container .mat-mdc-dialog-surface {
    padding: 20px;
  }
  &.disable-full-height .mat-mdc-dialog-surface {
    height: unset;
  }

  .close-button {
    position: sticky;
    top: 0;
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 99;
    button {
      background: none;
      border: none;
    }
    &.has-dialog-title {
      justify-content: space-between;
      padding-top: 0;
    }
    mat-icon {
      cursor: pointer;
      height: 20px;
      width: 20px;
      font-size: 20px;
    }
  }
}

mat-dialog-container {
  .dialog-inner-container {
    &.px-lg {
      padding: 0px 38px 30px 38px;
    }
    &.px-md {
      padding: 0px 20px 30px 20px;
    }
    &.pt-20 {
      padding-top: 20px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 16px;
    }

    &.no-padding {
      padding: 0;
      height: 100%;
      justify-content: space-between;
    }

    .action-wrapper {
      justify-content: flex-end;
      display: flex;
      c-form-button {
        margin-left: 10px;
      }
    }
  }

  .dialog-stick-bottom-bar {
    position: -webkit-sticky;
    position: sticky;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #e8e8e8;
    height: 100px;
    bottom: 0;
    background-color: #fff;
    padding: 0 20px;

    c-form-button,
    button:not(:last-child) {
      margin-right: 10px;
    }

    .action-help-text {
      color: #594e42;
      font-size: 12px;
    }
  }
}

// Hide Dialogs Class
.hidden-dialog {
  display: none;
  opacity: 0;
  pointer-events: none !important;
}
#mat-change-request, #mat-view-associated-member-history {
  .mat-mdc-dialog-surface{
    padding: 0 !important;
  }
}