// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
@import "src/theme/theme-variables";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.checkbox-density(-1);
@include mat.radio-density(-1);
$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Tondo",
  $headline: mat.define-typography-level(32px, 48px, 700),
  $subheading-2: mat.define-typography-level(14px, 18px, 700),
  $body-1: mat.define-typography-level(14px, 18px, 500),
);

// Override the typography in the core CSS.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

/*
 * HK1CRM Custom Color Palette - Start
 */
$md-mcgpalette0: (
  50: #e4e8ed,
  100: #bbc6d1,
  200: #8da1b3,
  300: #5f7b94,
  400: #3d5e7d,
  500: #1b4266,
  600: #183c5e,
  700: #143353,
  800: #102b49,
  900: #081d38,
  A100: #70a9ff,
  A200: #3d8aff,
  A400: #0a6bff,
  A700: #005ff0,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #fff,
    A700: #fff,
  ),
);

$md-mcgpalette1: (
  50: #f9ede9,
  100: #f1d3c7,
  200: #e7b6a2,
  300: #dd987d,
  400: #d68261,
  500: #cf6c45,
  600: #ca643e,
  700: #c35936,
  800: #bd4f2e,
  900: #b23d1f,
  A100: #ffeeeb,
  A200: #ffc4b8,
  A400: #ff9b85,
  A700: #ff866b,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

$hk1crm-frontend-portal-web-primary: mat.define-palette($md-mcgpalette0);
$hk1crm-frontend-portal-web-accent: mat.define-palette($md-mcgpalette1);

// The warn palette is optional (defaults to red).
$hk1crm-frontend-portal-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $hk1crm-frontend-portal-web-theme: mat.define-light-theme($hk1crm-frontend-portal-web-primary, $hk1crm-frontend-portal-web-accent, $hk1crm-frontend-portal-web-warn);

$hk1crm-frontend-portal-web-theme: mat.define-light-theme((
  color: (
    primary: $hk1crm-frontend-portal-web-primary,
    accent: $hk1crm-frontend-portal-web-accent,
    warn: $hk1crm-frontend-portal-web-warn
  ),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hk1crm-frontend-portal-web-theme);

/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme-variables";

// 3rd party libraries
@import "bootstrap/scss/bootstrap";

// Theme customization
@import "theme";

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
  margin: 0;
  font-family: Tondo, sans-serif;
  touch-action: manipulation;
}

html,
body {
  height: 100vh;
  overflow-y: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: #000;
  animation: spinner 0.8s linear infinite;
}

.mat-mdc-menu-item {
  font-size: 12px;
}

.dx-theme-generic-typography {
  font-family: Tondo, Arial, sans-serif;
}

.dx-theme-generic-typography input,
.dx-theme-generic-typography textarea {
  font-family: Tondo, Arial, sans-serif;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) > td:not(.dx-focused) {
  cursor: pointer;
}

.dx-datagrid-pager {
  border-top: none !important;
}

.label-uppercase {
  text-transform: uppercase;
}

.fit-content {
  width: fit-content;
}

.required-form-field label mat-label:not(.ocr-label-wrapper)::after {
  content: "*";
}

.required-input::after {
  content: "*";
}

.required-input-invalid {
  color: #dc3545 !important;
}

.subsection-title {
  color: #1b4266;
  font-weight: bold;
  font-size: 14px;
}

:focus {
  outline: none;
}

.mat-mdc-form-field {
  line-height: 18px;
}

.mat-icon.more-action {
  font-size: 17px;
  height: 17px;
  width: 17px;
  color: #333333;
}

mat-tab-header {
  // max-width: 980px;
}

.mat-mdc-tab-labels {
  justify-content: flex-start;
  .mat-mdc-tab{
    width: auto;
    min-width: 179px;
    max-width: 330px;
  }
}

.mat-form-field-wrapper {
  margin-bottom: 10px;
}

.mat-mdc-focus-indicator {
  background-color: transparent !important;
}

.form-stick-bottom-bar {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e8e8e8;
  height: 100px;
  bottom: 0;
  background-color: #fff;
  padding: 0 20px;

  c-form-button,
  button:not(:last-child) {
    margin-right: 10px;
  }

  .action-help-text {
    color: #594e42;
    font-size: 12px;
  }
}

@supports (-webkit-touch-callout: none) {
  .form-stick-bottom-bar {
    align-items: flex-start;
    padding-top: 20px;
    bottom: calc(max(30px, env(safe-area-inset-bottom)));
  }
}

@media screen and (device-width: $tablet-portait-width), screen and (device-width: $tablet-portait-width) {
  /* For general iPad layouts */
  // .form-stick-bottom-bar {
  //   height: 140px;
  //   padding-bottom: 50px;
  // }
}

@media only screen and (min-device-width: $mobile-portait-width) and (max-device-width: $tablet-landscape-width) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: $mobile-portait-width) and (max-device-width: $tablet-landscape-width) and (orientation: landscape) {
  /* For landscape layouts only */
}

.mat-mdc-select .ng-star-inserted {
  //white-space: normal;
}

.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable {
  padding-right: 1px;
}

.mat-select-panel mat-option.mat-mdc-option {
  height: unset;
}

.mat-option-text.mat-option-text {
  white-space: normal;
  line-height: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.mat-select-panel mat-option.mat-mdc-option.reject-reason-list {
  height: unset;
  .mat-option-text {
    white-space: normal;
    line-height: 1.8em;
    padding: 8px 0;
  }
}

app-html-tooltip {
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  height: 13px;
  width: 13px;
  align-items: center;
  margin-left: 6px;
}

.result-container ::ng-deep rewards-gift-collection-item {
  margin-top: 8px;
  margin-bottom: 8px;
}

// Rewards and gifts
.scroll-container {
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  .scrollable-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: unset !important;
    height: auto !important;
  }
}

.rewards-and-spending-flex-container {
  display: flex;
  flex-direction: column;

  .search-container {
    height: fit-content;
  }
  .listing-container {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .full-width-cards {
      rewards-gift-collection-item,
      eligible-gifts-item {
        width: 100%;
      }
    }

    .half-width-cards {
      rewards-gift-collection-item,
      eligible-gifts-item {
        width: 50%;

        &:nth-child(2n + 1) {
          padding-right: 10px;
        }
        &:nth-child(2n + 2) {
          padding-left: 10px;
        }
      }
    }
  }
}

.reserved-ec-flex-container {
  display: flex;
  flex-direction: column;

  .search-container {
    height: fit-content;
  }
  .listing-container {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .full-width-cards {
      rewards-gift-collection-item,
      eligible-gifts-item {
        width: 100%;
      }
      reserved-ec-item {
        width: 100%;
      }
    }

    .half-width-cards {
      rewards-gift-collection-item,
      eligible-gifts-item {
        width: 50%;

        &:nth-child(2n + 1) {
          padding-right: 10px;
        }
        &:nth-child(2n + 2) {
          padding-left: 10px;
        }
      }
      reserved-ec-item {
        width: 50%;

        &:nth-child(2n + 1) {
          padding-right: 10px;
        }
        &:nth-child(2n + 2) {
          padding-left: 10px;
        }
      }
    }
  }
}

.delete-input-btn {
  &.normal {
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
  }
  &.datepicker {
    height: 24px !important;
    width: 24px !important;
    min-width: 24px !important;
    margin-top: -6px !important;
    margin-right: 5px !important;
    line-height: 24px !important;
    .mat-button-wrapper {
      line-height: 24px;
    }
  }

  &.textarea {
    line-height: 24px !important;
    .mat-button-wrapper {
      line-height: 24px;
    }
  }

  &.select {
    height: 20px !important;
    width: 20px !important;
    min-width: 20px !important;

    line-height: 20px !important;
    .mat-button-wrapper {
      line-height: 20px;
    }
  }

  padding-left: 0px !important;
  padding-right: 0px !important;
  .mat-button-focus-overlay {
    background-color: #000 !important;
  }
}

.delete-input-btn-textarea {
  line-height: 24px !important;
  .mat-button-wrapper {
    line-height: 24px;
  }
}
.delete-input-btn-datepicker {
  margin-top: -6px !important;
  margin-right: 5px !important;
  line-height: 24px !important;
  .mat-button-wrapper {
    line-height: 24px;
  }
}

.delete-text-area-btn-container {
  position: absolute;
  top: 0;
  right: 15px;
}

.mat-select-delete-icon-wrapper {
  display: table-cell;
  vertical-align: middle;
}

hr.full-width-separator {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-mdc-menu-panel {
  min-height: auto;
}

.section-title {
  font-size: 16px;
  color: #1b4266;
  font-weight: bold;
  margin-bottom: 20px;
  align-items: center;

  .sub-title {
    font-size: 14px !important;
    color: "#333333" !important;
  }

  .tips {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #d83434;
    margin-left: 10px;

    .info-button {
      font-size: 13px;
      width: 13px;
      height: 13px;
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base{
  padding: 0px;
}
.mdc-menu-surface.mat-mdc-select-panel{
  padding: 0 !important;
}

.reminder-Rpt{
  .align-items-center{
    margin-top: -16px;
    // margin-left: -10px;
  }
}

.mat-mdc-tab-header{
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}
.formly-toggle{
  .mdc-line-ripple{
    display: none;
  }
  .mdc-form-field{
    label{
      margin:0 0 0 10px;
      cursor: pointer;
    }
  }
}