@import "../theme-variables.scss";

/*
 * Override mat-form style
 */

.form-top {
  padding-top: 10px;
}

/*
  The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
.mat-mdc-form-field-infix {
  // margin: 0 0 8px 16px;
  font-size: 14px;
  flex: 1 !important;
}

.mdc-text-field--disabled.mdc-text-field--filled{
  background-color: unset;
}

.formly-row {
  height: 90px;
}

.formly-toggle {
  .mat-mdc-slide-toggle-checked {
    .mdc-switch__track {
      background-color: rgba(27, 66, 102, 0.54);

      .mat-slide-toggle-thumb-container {
        .mat-slide-toggle-ripple .mat-ripple-element {
          background-color: #1b4266;
        }

        .mat-slide-toggle-thumb {
          background-color: #1b4266;
        }
      }
    }
  }
}

mat-form-field.mat-form-field-invalid {
  animation: bigger;
  animation-duration: 0.3s;
  margin-bottom: 17px;
}

/* Safari only override */
// .mat-input-element:disabled,
// .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
//   -webkit-text-fill-color: #888;
//   opacity: 1; __<<ngThemingMigrationEscapedComment2>>__
// }

/* Safari only override, disabled input field too light */
/*
  The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
.mat-mdc-input-element:disabled:not(:placeholder-shown),
.mat-mdc-select.mat-select-disabled:not(:placeholder-shown) {
  -webkit-text-fill-color: #888;
  opacity: 1; /* required on iOS */
}

// Simple form field style
// No border and smaller size
.simple-field {
  .mat-mdc-form-field-infix {
    margin: 0;
    padding: 0 !important;
    min-height: 0;
  }

  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    display: none !important;
  }

  div.mat-mdc-select-arrow::after {
    line-height: inherit;
  }
  

  .mat-mdc-select-value {
    vertical-align: middle;
  }

  .mat-mdc-form-field-bottom-align {
    display: none;
  }
}

.form-overlay {
  opacity: 0.3;
  cursor: progress;
  mat-form-field {
    pointer-events: none;
  }
  .spinner-container-span {
    position: relative;
    top: 50%;
    left: 50%;
    mat-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }
}

.multiple-select {
  /*
  The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
  .mat-mdc-form-field-flex {
    align-items: center;
  }
}
textarea {
  resize: none !important;
}

.mat-mdc-form-field-icon-suffix ~ .mat-mdc-form-field-icon-suffix {
  padding: 0;
}

.mat-mdc-form-field-icon-suffix{
  display: flex;
  align-items: center;
}

.mat-mdc-select-arrow{
  height: 18px !important;
  display: flex !important;
  align-items: center!important;
}
// .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
//   color:#1b4266 ;
// }
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper{
  transform: translateY(-2px)!important;
}
.mdc-text-field{
  overflow: inherit !important;
  will-change: auto !important;
}
.mat-focused {
  background-color: unset !important;
}

// .mat-mdc-form-field:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
//   border-bottom-color: rgba(0, 0, 0, 0.42) !important;
// }

// .mat-mdc-form-field.mat-form-field-invalid:hover .mdc-line-ripple::before {
//   border-bottom-color: #f44336 !important;
// }


// .mat-form-field-disabled{
//   .mdc-line-ripple::before, .mdc-line-ripple::after{
//     height: 1px;
//     border-bottom-style: none!important;
//   }
//   .mdc-text-field--disabled .mdc-line-ripple::before{
//     background-image: linear-gradient(to right,rgba(0,0,0,.42) 0%,rgba(0,0,0,.42) 33%,transparent 0%);
//     background-size: 4px 100%;
//     background-repeat: repeat-x;
//   }
// }

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
  top: 26px;
}
.mat-option{
  height: 48px;
}

.reset-btn {
  background-color: #e8e8e8 !important;
  color: #888!important;
}
// .mdc-text-field{
//   padding: 0 10px !important;
// }
.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 10px !important;
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled){
  background: transparent;
}
.mdc-floating-label{
  width: 133%;
 }
