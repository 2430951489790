@import "../theme-variables.scss";

/** GLOBAL BUTTON STYLING **/

button:focus {
  outline: none;
}
.mat-mdc-button:active {
  background-color: transparent !important;
}
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  font-family: Tondo, Helvetica, Arial, Verdana, Tahoma, Sans-Serif;
  font-weight: 600;
}
.mat-mdc-button, .mat-mdc-outlined-button{
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}

button,
c-form-button {
  &.is-cancel {
    background-color: #e8e8e8;
    color: #888888;
  }

  &.is-reject {
    background-color: #ff2032;
    border: 1px solid #ff2032;
  }
}

c-form-button {
  a:hover {
    text-decoration: none;
  }
}

.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity:0 !important;
}
.mat-mdc-unelevated-button:disabled{
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mat-mdc-icon-button .mat-mdc-button-touch-target{
  width: 40px;
  height: 40px;
}

