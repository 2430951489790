/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0073dd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
);

// Project color scheme - provided by UI team
$blue-grey: #586e83;
$brown-grey: #888;
$btn-bg-grey: #e8e8e8;
$error-light: #b00020;
$success-green: #93b371;
$error-red: #b53636;
$dark-slate-blue: #1b4266;
$denim-blue: #36519b;
$peacock-blue: #0052a7;
$royal: #0c2c84;
$strawberry: #ff2032;
$very-light-pink: #f3f3f3;
$white: #fafafa;
$brownish-grey: #666666;
$raw-sienna: #d06d45;
$mine-shaft: #333333;

// Components
$breadcrumb-height: 64px;
$menu-height: 64px;
$common-reminder-height: 48px;
$main-wrapper-padding: 15px;
$footer-height: 100px;
$page-top-section: calc(#{$menu-height} + #{$breadcrumb-height});

// Spacer
$spacer: 20px;

// Device-width
$tablet-portait-width: 768px;
$tablet-landscape-width: 1024px;
$mobile-portait-width: 481px;

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
