// special handle for the ng select wrapped in mat form field
.ng-select-mat-form-field .ng-select {
  padding-bottom: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.ng-select-mat-form-field .ng-select .ng-select-container:after {
  border-bottom: 0;
}

.ng-select-mat-form-field .ng-select .ng-select-container .ng-value-container {
  padding: 0;
  border-top: 0;
}

.ng-select-mat-form-field .ng-select .ng-select-container {
  min-height: fit-content;
  // height: 26px;
}

.ng-select-mat-form-field .ng-dropdown-panel.ng-select-bottom {
  top: calc(100% + 0.65em);
  left: calc(0% - 1.2em);
  width: calc(100% + 1.2em);
}

.ng-select-mat-form-field .mat-mdc-form-field-flex > div {
  padding: 0.4375em 0;
  margin-bottom: 8px;
}

.ng-select-mat-form-field .ng-value.ng-star-inserted {
  left: 0;
  position: absolute;
  width: 100%;
  max-width: calc(100% - 1.5em);
}

.ng-select-mat-form-field .ng-clear-wrapper {
  // visibility: hidden;
  height: 17px;
}

.ng-select-mat-form-field span.ng-clear-wrapper.ng-star-inserted {
  left: 12px;
}

.ng-select-mat-form-field span.ng-clear-wrapper.ng-star-inserted:hover {
  color: rgba(0, 0, 0, 0.54);
}

.ng-select-mat-form-field .ng-select .ng-clear-wrapper .ng-clear {
  font-size: 25px;
}

.ng-select-mat-form-field span.ng-arrow-wrapper {
  visibility: hidden;
}

.ng-select-mat-form-field .ng-placeholder {
  // font-weight: 300;
  font-weight: 400;
}

.ng-select-mat-form-field .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: none;
  color: rgba(0, 0, 0, 0.54);
  // font-weight: 300;
  font-family: Tondo, Helvetica, Arial, Verdana, Tahoma, Sans-Serif;
  font-size: 14px;
}

.ng-select-mat-form-field .ng-select.ng-select-filtered .ng-placeholder,
.ng-select-mat-form-field .ng-select-container.ng-has-value .ng-placeholder {
  visibility: hidden;
}

.ng-select-mat-form-field .ng-dropdown-panel {
  border-radius: 0px 0px 0px 4px;
}

.ng-select-mat-form-field .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  position: initial;
  input {
    line-height: 18px;
  }
}

.ng-select-mat-form-field .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  bottom: 3px !important;
}

.ng-select-mat-form-field .ng-select.wrap-text {
  .ng-select-container {
    .ng-value {
      white-space: normal !important;
      max-width: calc(100% - 1.7em);
      display: contents;
      .ng-value-label {
        display: inline-block;
        word-wrap: break-word;
      }
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 18px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  white-space: normal !important;
}
